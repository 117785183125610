<template>
  <footer
    class="footer flex h-[calc(100svh+1px)] w-screen flex-col justify-between overflow-clip bg-dark-green pb-spacing-04 pt-spacing-06 text-ivory md:h-[calc(100dvh+1px)] lg:pb-spacing-04-desktop lg:pt-spacing-06-desktop"
    data-theme="dark-green-footer"
  >
    <div class="content z-[1] flex h-full flex-col gap-spacing-06">
      <div
        class="logo-links flex h-full flex-col justify-between lg:mb-auto lg:mt-auto lg:h-fit lg:flex-row lg:items-start lg:justify-normal"
      >
        <div class="logo w-[var(--cols-8) relative z-[0] lg:w-[var(--cols-4)]">
          <nuxt-link to="/" class="w-full" aria-label="VANDSTROM logo">
            <NuxtIcon name="full-logo" class="icon h-auto w-full" filled></NuxtIcon
          ></nuxt-link>
        </div>
        <div class="links relative lg:ml-[calc(var(--push-2)+var(--grid-gap))]">
          <div
            class="columns-container ml-[var(--push-3)] gap-x-[var(--grid-gap)] gap-y-spacing-04 lg:ml-0 lg:gap-x-[calc(var(--push-1)+var(--grid-gap))] lg:gap-y-spacing-04-desktop"
          >
            <ul
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              class="column flex w-[var(--cols-3)] flex-col gap-spacing-04 lg:w-[var(--cols-2)] lg:gap-spacing-04-desktop"
            >
              <li
                data-cursor="empty"
                v-for="link in column"
                :key="link.href"
                class="h-fit w-fit list-none"
              >
                <ui-font-text type="label">
                  <span
                    @click="openModal"
                    v-if="link.type === 'contact'"
                    class="contact-opener cursor-pointer"
                  >
                    {{ link.title }}
                  </span>
                  <nuxt-link v-else :aria-label="link.title" :to="link.href" :target="link.target">
                    {{ link.title }}
                  </nuxt-link>
                </ui-font-text>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="infos relative z-[var(--z-index-content-over-drop)] mt-10 flex w-[var(--cols-6)] flex-col lg:mt-0 lg:w-[var(--cols-8)] lg:flex-row lg:items-end"
      >
        <div class="flex w-[var(--cols-6)] flex-col gap-6 lg:w-[var(--cols-4)]">
          <div class="flex flex-col gap-6" v-if="infos && infos.addresses">
            <div v-for="address in infos.addresses">
              <ui-font-text type="body">{{ address.addressLine1 }}</ui-font-text>
              <ui-font-text type="body" v-if="address.addressLine2">{{
                address.addressLine2
              }}</ui-font-text>
              <div class="locality flex flex-row">
                <ui-font-text type="body" v-if="address.locality"
                  >{{ address.locality }},&nbsp;</ui-font-text
                >
                <ui-font-text type="body" v-if="address.postalCode"
                  >{{ address.postalCode }},&nbsp;</ui-font-text
                >
                <ui-font-text type="body" v-if="address.countryCode">{{
                  address.countryCode
                }}</ui-font-text>
              </div>
            </div>
          </div>

          <div data-cursor="empty">
            <ui-font-text type="body" v-if="infos && infos.phone"
              ><a :href="`tel:${infos.phone}`" aria-label="Phone number">{{
                infos.phone
              }}</a></ui-font-text
            >
          </div>
        </div>
        <button
          data-cursor="empty"
          aria-label="Scroll back to top button"
          @click="lenisScrollTo(0)"
          class="back-to-top mt-spacing-06 flex w-fit flex-row items-center gap-[10px] text-left lg:ml-[calc(var(--push-2)+var(--grid-gap))] lg:mt-0"
        >
          <ui-font-text type="label">Back to top</ui-font-text>
          <NuxtIcon name="chevron-right" class="icon w-[12px] -rotate-90"></NuxtIcon>
        </button>
      </div>
    </div>
    <nuxt-img class="grainy-drop" src="/drop-dark.png" alt="water-drop"/>
  </footer>
</template>

<script setup>
const { globalData } = useVandState()
const data = ref(globalData.value.footer.data)
const infos = ref(globalData.value.infos)
const { lenis } = useSmoothScroll()

const lenisScrollTo = (scrollValue) => {
  lenis.value.scrollTo(scrollValue, {
    duration: 1,
    easing: (t) => {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
  })
}

const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})

const columns = computed(() => {
  const items = data.value
  const columns = []
  let currentColumn = []

  items.forEach((item, index) => {
    if (index > 0 && index % 4 === 0) {
      columns.push(currentColumn)
      currentColumn = []
    }
    currentColumn.push(item)
  })

  if (currentColumn.length) {
    columns.push(currentColumn)
  }

  return columns
})

const openModal = () => {
  useEvent('modal:open', {
    data: null,
    type: 'contact',
  })
}
</script>

<style scoped>
.icon:deep(svg) {
  width: 100%;
  height: 100%;
}

.columns-container {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 1 1 var(--cols-3);
  max-width: 100%;
}

@media (min-width: 640px) {
  .column {
    flex: 0 1 calc(var(--cols-2) / 1);
    /* max-width: calc(100% / 3); */
  }
}

.footer {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
</style>
