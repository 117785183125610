<script setup>
import {
  DrawerContent,
  DrawerOverlay,
  DrawerTitle,
  DrawerPortal,
  DrawerRoot,
  DrawerTrigger,
  DrawerDescription,
} from 'vaul-vue'
import Parallaxy from '@lucien144/vue3-parallaxy'

const props = defineProps({
  card: Object,
})
const isOpen = ref(false)
const randomParallaxes = ref([])

onMounted(() => {
  if (props.card.foregroundMedias.length < 1) return
  randomParallaxes.value = Array.from({ length: props.card.foregroundMedias.length }, () =>
    getRandomFloatBetween(0.04, 0.1, 2),
  )
})

const handleUpdate = (open) => {
  if (open) {
    // console.log('open')
  }
}
</script>

<template>
  <div
    class="flip-card z-[var(--z-index-content-over-drop)] aspect-card h-full overflow-hidden rounded-corner-02 lg:aspect-portrait xl:rounded-corner-02-desktop"
  >
    <client-only>
      <DrawerRoot @update:open="handleUpdate">
        <DrawerTrigger
          ref="cardEl"
          class="flip-card-side flip-card-front flex h-full w-full flex-col justify-between border border-stroke-dark bg-focus-light p-[20px] text-ivory lg:p-[40px]"
        >
          <div class="z-[3] flex w-full flex-row justify-between">
            <ui-font-text type="label" v-if="card.trumpet" class="text-ivory">{{
              card.trumpet
            }}</ui-font-text>
            <NuxtIcon class="icon h-[12px] w-[12px]" name="plus" filled />
          </div>
          <h2
            v-if="card.headline"
            class="pointer-events-none z-[3] text-left text-ivory font-display text-h3 xl:text-h3Desktop font-normal break-words ultra:text-h3Ultra"
            >{{ card.headline }}</h2
          >
          <button
            aria-label="Flip card button"
            class="absolute right-0 top-0 z-[2] h-full w-full cursor-pointer bg-transparent"
            @click="openDrawer"
            id="flip-card-btn-turn-to-back"
            data-cursor="plus"
          ></button>
          <client-only>
            <div
              class="overlay-layer pointer-events-none absolute left-0 top-0 z-[2] h-full w-full"
            ></div>
            <div class="absolute left-0 top-0 h-full w-full">
              <div
                class="foreground-medias pointer-events-none absolute left-0 top-0 z-[2] flex h-full w-full items-center justify-center"
                v-for="(media, index) in card.foregroundMedias"
                :key="index"
              >
                <Parallaxy :speed="0" class="h-full w-full">
                  <div class="clip h-full overflow-hidden">
                    <Parallaxy
                      :speed="
                        card.foregroundMedias.length > 1 ? randomParallaxes[index] * 1000 : 20
                      "
                      class="h-[110%] origin-center"
                    >
                      <component-element-media
                        class="media h-[120%]"
                        v-if="media"
                        :media="media"
                      ></component-element-media>
                    </Parallaxy>
                  </div>
                </Parallaxy>
              </div>
              <div
                v-if="card.backgroundMedia"
                class="background pointer-events-none absolute left-0 top-0 z-[1] flex h-full w-full origin-center items-center justify-center object-cover"
              >
                <Parallaxy :speed="0" class="h-full w-full">
                  <div class="clip h-full overflow-hidden">
                    <Parallaxy :speed="20" class="h-[110%] origin-center">
                      <component-element-media
                        ref="media"
                        class="media-hero h-[120%] w-full"
                        :media="card.backgroundMedia"
                      ></component-element-media>
                    </Parallaxy>
                  </div>
                </Parallaxy>
              </div>
            </div>
          </client-only>
        </DrawerTrigger>
        <DrawerPortal>
          <DrawerOverlay class="fixed inset-0 z-[900] bg-black/20 backdrop-blur-[2px]" />

          <DrawerContent
            class="fixed bottom-0 left-2 right-2 z-[1000] h-fit rounded-t-corner-01 bg-light-green text-ivory outline-none"
          >
            <DrawerTitle class="flex cursor-grab justify-center p-4">
              <div class="h-1 w-8 rounded-full bg-ivory/30"></div>
            </DrawerTitle>

            <div
              class="max-h-[calc(100dvh-7rem)] overflow-auto overscroll-contain px-4 pb-8 pt-4 md:pb-10 md:pt-4"
              data-vaul-no-drag
            >
              <!-- content here -->
              <ui-font-text type="body" v-if="card.body" class="z-[3] hidden text-ivory md:block">
                {{ card.body }}
              </ui-font-text>
              <ui-font-text
                type="smallBody"
                v-if="card.body"
                class="z-[3] block text-ivory md:hidden"
              >
                {{ card.body }}
              </ui-font-text>
              <!-- /content here -->
            </div>
          </DrawerContent>
        </DrawerPortal>
      </DrawerRoot>
    </client-only>
  </div>
</template>
