<script setup>
import Parallaxy from '@lucien144/vue3-parallaxy'

const props = defineProps({
  trumpet: String,
  headline: String,
  cta: Object,
  media: Object,
})
</script>
<template>
  <section
    class="callout relative flex pb-spacing-06 lg:h-full lg:pb-spacing-06-desktop"
    :class="{
      'border-t': !media,
      'border-stroke-light': props.currentTheme === 'black' || props.currentTheme === 'dark-green',
      'border-stroke-dark': props.currentTheme !== 'black' || props.currentTheme !== 'dark-green',
      'min-h-[80vh] lg:min-h-screen lg:pt-spacing-10-desktop': media,
      'pt-spacing-08 lg:pt-spacing-08-desktop': !media,
    }"
    ref="callout"
  >
    <ui-bleed
      class="relative z-[var(--z-index-content-over-drop)] mt-auto flex transform flex-col gap-[94px] text-[var(--text-theme)] lg:gap-[120px]"
    >
      <ui-font-text
        class="ml-[var(--push-3)] lg:ml-[var(--push-6)]"
        type="label"
        :class="media ? 'text-white mix-blend-difference' : ''"
        v-if="trumpet"
        >{{ trumpet }}</ui-font-text
      >
      <ui-font-text
        class="indent-[calc(var(--push-3))] lg:indent-[calc(var(--push-6))]"
        type="h1"
        :class="media ? 'text-white mix-blend-difference' : ''"
        v-if="headline"
        >{{ headline }}</ui-font-text
      >

      <ui-call-to-action
        v-if="cta"
        :cta="cta"
        class="ml-0 lg:ml-[var(--push-6)]"
        color="bg-[var(--text-theme)]"
      ></ui-call-to-action>
    </ui-bleed>
    <div class="absolute left-0 top-0 h-full w-full overflow-hidden">
      <Parallaxy :speed="0" v-if="media" class="h-full w-full">
        <div class="clip flex h-full justify-center overflow-hidden">
          <Parallaxy :speed="35" class="h-[110%] origin-center ultra:h-[100vw]">
            <div class="media-overlay absolute left-0 top-0 z-[1] h-full w-full"></div>
            <component-element-media
              class="media h-[120%]"
              v-if="media"
              :media="media"
            ></component-element-media>
          </Parallaxy>
        </div>
      </Parallaxy>
    </div>
  </section>
</template>

<style scoped>
.media-overlay {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}
</style>
