<script setup>
const props = defineProps({ step: Object, index: Number, length: Number });

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <div
    class="relative grid grid-cols-1 gap-spacing-06 lg:flex lg:grid-cols-2 lg:flex-row lg:gap-[var(--grid-gap)]"
    ref="stepContent"
    :class="{ 'has-border': index !== length - 1 }"
  >
    <div
      v-if="!isMobile"
      class="sticky top-[20%] mb-spacing-06-desktop ml-[var(--push-1)] aspect-portrait w-[var(--cols-4)] shrink-0 overflow-hidden ultra:top-[10%]"
    ></div>
    <!-- <component-element-media
      v-if="!isMobile"
      ref="media"
      class="media aspect-portrait w-full overflow-hidden sm:relative lg:sticky lg:top-[20%] lg:ml-[var(--push-1)] lg:w-[var(--cols-4)]"
      :media="step.media"
      :is-rounded="true"
    ></component-element-media> -->
    <div class="content-wrapper flex flex-col lg:-ml-[var(--push-5)]">
      <ui-font-text
        type="label"
        class="ml-[var(--push-3)] pb-spacing-07 lg:ml-0 lg:pb-spacing-07-desktop"
        v-if="step.trumpet"
        >{{ step.trumpet }}</ui-font-text
      >
      <ui-font-text
        v-if="step.headline"
        class="w-full pb-spacing-06 lg:w-[var(--cols-5)] lg:pb-spacing-06-desktop lg:indent-0"
        type="h2"
        :id="step.anchorId"
        >{{ step.headline }}</ui-font-text
      >
      <div
        ref="media"
        v-if="!step.media && isMobile"
        class="media aspect-portrait w-full bg-[red] sm:relative lg:sticky lg:top-[20%] lg:ml-[var(--push-1)] lg:w-[var(--cols-4)]"
      ></div>
      <component-element-media
        v-else-if="isMobile"
        ref="media"
        class="media mb-spacing-06 aspect-portrait w-full overflow-hidden sm:relative lg:sticky lg:top-[20%] lg:ml-[var(--push-1)] lg:w-[var(--cols-4)]"
        :media="step.media"
        :is-rounded="true"
      ></component-element-media>
      <ui-rich-text
        :class="{ 'mb-spacing-06 lg:mb-[64px]': index !== length - 1 }"
        class="lg:lg-spacing-06-desktop lg:w-[var(--cols-6)]"
        v-if="step.richText"
        :rte="step.richText"
      />
    </div>
  </div>
</template>

<style>
.has-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100vw - 2 * var(--grid-bleed));
  height: 1px;
  background: var(--stroke-dark);
  right: 0;

  @media (min-width: 768px) {
    width: var(--cols-6);
    right: calc(-1 * var(--push-1));
  }
}
</style>
