<template>
  <div
    class="flip-card z-[var(--z-index-content-over-drop)] flex aspect-card h-full items-center justify-center lg:aspect-portrait"
  >
    <div class="perspective relative h-full w-full">
      <div
        :class="[
          'transform-style-preserve-3d absolute h-full w-full transition-transform duration-1000',
          { 'rotate-y-180': flipped },
        ]"
        id="flip-card"
      >
        <div
          ref="cardEl"
          @mousemove="handleMouseMove"
          class="flip-card-side flip-card-front flex flex-col justify-between overflow-hidden rounded-corner-02 border border-stroke-dark bg-focus-light p-spacing-04-desktop text-ivory xl:rounded-corner-02-desktop"
        >
          <div class="pointer-events-none z-[3] flex flex-row justify-between">
            <ui-font-text type="label" v-if="card.trumpet" class="text-ivory">{{
              card.trumpet
            }}</ui-font-text>
            <NuxtIcon class="icon h-[16px] w-[16px]" name="plus" filled />
          </div>
          <h2
            v-if="card.headline"
            class="pointer-events-none z-[3] text-left text-ivory font-display text-h3 xl:text-h3Desktop font-normal break-words ultra:text-h3Ultra"
            >{{ card.headline }}</h2
          >
          <button
            aria-label="Flip card button"
            class="absolute right-0 top-0 z-[2] h-full w-full cursor-pointer bg-transparent"
            @click="flipCard"
            id="flip-card-btn-turn-to-back"
            data-cursor="plus"
          ></button>
          <client-only>
            <div
              class="overlay-layer pointer-events-none absolute left-0 top-0 z-[2] h-full w-full"
            ></div>
            <div
              class="medias-wrapper pointer-events-none absolute left-0 top-0 z-[1] h-full w-full"
              ref="background"
            >
              <div
                class="parallax-media pointer-events-none absolute left-0 top-0 z-[2] flex h-full w-full items-center justify-center"
                v-for="(media, index) in card.foregroundMedias"
                :key="index"
              >
                <component-element-media
                  v-if="media"
                  ref="media"
                  class="media-hero h-[120%] w-[120%] object-cover"
                  :media="media"
                  :data-parallax="randomParallaxes[index]"
                ></component-element-media>
              </div>
              <div
                class="background pointer-events-none absolute left-0 top-0 z-[1] flex h-full w-full origin-center items-center justify-center object-cover"
              >
                <component-element-media
                  v-if="card.backgroundMedia"
                  ref="media"
                  class="media-hero h-full w-full origin-center object-cover lg:h-[120%] lg:w-[120%]"
                  :media="card.backgroundMedia"
                  :data-parallax="0.01"
                ></component-element-media>
              </div>
            </div>
          </client-only>
        </div>
        <div
          class="flip-card-side flip-card-back rotate-y-180 flex transform overflow-hidden rounded-corner-02-desktop bg-light-green p-spacing-04-desktop text-ivory"
        >
          <ui-font-text type="body" class="mt-auto text-ivory">{{ card.body }}</ui-font-text>
          <button
            aria-label="Flip card button"
            class="absolute right-0 top-0 h-full w-full cursor-pointer bg-transparent"
            @click="flipCard"
            id="flip-card-btn-turn-to-front"
            data-cursor="cross"
          ></button>
          <NuxtIcon
            class="icon icon-close pointer-events-none absolute right-spacing-04-desktop top-spacing-04-desktop h-[16px] w-[16px] -rotate-45 fill-ivory"
            name="plus"
            filled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Parallaxy from '@lucien144/vue3-parallaxy'

import { gsap } from 'gsap'
const props = defineProps({
  card: Object,
})
// console.log('card', props.card)
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 1280
})
const flipped = ref(false)

const flipCard = () => {
  flipped.value = !flipped.value
}
const randomParallaxes = ref([])
const background = ref(null)
const cardEl = ref(null)

const mouseX = ref(0)
const mouseY = ref(0)

function handleMouseMove(event) {
  if (isMobile.value) return
  if (props.card.foregroundMedias.length < 1) return
  const items = background.value.querySelectorAll('.media')
  const cardRect = cardEl.value.getBoundingClientRect()
  mouseX.value = event.clientX - cardRect.left - cardRect.width / 2
  mouseY.value = event.clientY - cardRect.top - cardRect.height / 2
  items.forEach((item, index) => {
    const animationFactor = items[index].getAttribute('data-parallax')
    gsap.to(item, {
      x: -mouseX.value * animationFactor,
      y: -mouseY.value * animationFactor,
      duration: 0.75,
    })
  })
}

onMounted(() => {
  if (props.card.foregroundMedias.length < 1) return
  randomParallaxes.value = Array.from({ length: props.card.foregroundMedias.length }, () =>
    getRandomFloatBetween(0.04, 0.1, 2),
  )
})
</script>

<style scoped>
.perspective {
  perspective: 1500px;
}
.flip-card-side {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
}
.rotate-y-180 {
  transform: rotateY(-180deg);
}
.transform-style-preserve-3d {
  transform-style: preserve-3d;
  will-change: transform;
}

.icon:deep(svg) {
  fill: var(--ivory);
  width: 100%;
  height: 100%;
}
.icon-close:deep(svg) {
  fill: var(--ivory);
  width: 100%;
  height: 100%;
}

.media {
  position: absolute;
  transform: translateX(var(--rotate-x)) translateY(var(--rotate-y));
  transform-style: preserve-3d;
  will-change: transform;
}

.overlay-layer {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
}

.flip-card-front,
.flip-card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-card-side {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
</style>
