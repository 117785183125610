<script setup>
const props = defineProps({
  trumpet: String,
  headline: String,
  persons: Array,
  countLabel: String,
})
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 1280
})
</script>

<template>
  <section
    class="content pb-spacing-06 pt-spacing-08 lg:pb-spacing-06-desktop lg:pt-spacing-08-desktop"
    ref="content"
  >
    <ui-bleed class="lg:spacing-06-desktop pb-spacing-06">
      <div class="content-wrapper flex flex-col gap-spacing-07 lg:gap-spacing-07-desktop">
        <ui-font-text
          v-if="trumpet"
          type="label"
          class="ml-[var(--push-3)] w-[var(--cols-6)] lg:ml-[var(--push-6)]"
          >{{ trumpet }}</ui-font-text
        >
        <ui-font-text
          v-if="headline"
          class="ml-0 w-[var(--cols-9)] indent-[calc(var(--push-3))] lg:ml-[var(--push-6)] lg:w-[var(--cols-12)] lg:w-[var(--cols-6)] lg:indent-0"
          type="h2"
          >{{ headline }}</ui-font-text
        >
      </div>
      <!-- <component-element-circular-grid :persons="persons" /> -->
    </ui-bleed>
    <component-element-peoples-line v-if="!isMobile" :persons="persons" :countLabel="countLabel" />
    <component-element-people-slider v-else :persons="persons" :countLabel="countLabel" />
  </section>
</template>
