<script setup>
import Parallaxy from '@lucien144/vue3-parallaxy'

const props = defineProps({
  hasSidesPadding: {
    type: Boolean,
    default: true,
  },
  trumpet: String,
  headline: String,
  anchorId: String,
  body: String,
  media: Object,
  mediaCaption: Object,
  cta: Object,
  currentTheme: String,
  hasBorder: {
    type: Boolean,
    default: true,
  },
  isHero: {
    type: Boolean,
    default: false,
  },
})

const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 1024
})

const bleedCompoenent = resolveComponent('ui-bleed')
</script>

<template>
  <section
    class="content"
    :class="{
      'border-t': hasBorder,
      'border-stroke-light': props.currentTheme === 'black' || props.currentTheme === 'dark-green',
      'border-stroke-dark': props.currentTheme !== 'black' || props.currentTheme !== 'dark-green',
    }"
    ref="content"
    :id="anchorId"
  >
    <component
      :is="hasSidesPadding ? bleedCompoenent : 'div'"
      class="gap-spacing-14 relative z-[var(--z-index-content-over-drop)] grid grid-cols-1 py-spacing-06 lg:grid-cols-2 lg:gap-[var(--grid-gap)] lg:py-spacing-06-desktop"
    >
      <Parallaxy
        v-if="media && !isMobile"
        :speed="40"
        direction="opposite"
        class="aspect-portrait w-[var(--cols-6)] shrink-0 sm:relative lg:mb-spacing-06-desktop lg:ml-[var(--push-1)] lg:w-[var(--cols-4)]"
      >
        <div
          class="relative aspect-portrait w-full overflow-hidden rounded-corner-02 lg:rounded-corner-02-desktop"
        >
          <div
            v-if="mediaCaption"
            class="media-caption absolute bottom-0 left-0 z-[1] flex h-fit w-full p-spacing-03-desktop"
          >
            <div class="caption mt-auto flex flex-col gap-1 text-focus-light">
              <ui-font-text type="h2" v-if="mediaCaption.title">
                {{ mediaCaption.title }}
              </ui-font-text>
              <ui-font-text type="smallBody" v-if="mediaCaption.text">
                {{ mediaCaption.text }}
              </ui-font-text>
            </div>
          </div>
          <Parallaxy
            :speed="50"
            direction="opposite"
            class="aspect-portrait w-full overflow-hidden rounded-corner-02 lg:rounded-corner-02-desktop"
          >
            <component-element-media
              ref="media"
              class="media h-full"
              :media="media"
              :is-rounded="true"
            ></component-element-media>
          </Parallaxy>
        </div>
      </Parallaxy>

      <div ref="contentWrapper" class="content-wrapper relative flex flex-col">
        <div class="content-wrapper-inner relative">
          <div class="heading flex flex-col gap-spacing-07 lg:gap-spacing-07-desktop">
            <ui-font-text
              v-if="trumpet"
              type="label"
              class="ml-[var(--push-3)] w-[var(--cols-6)] pt-spacing-06 lg:ml-0 lg:ml-[var(--push-6)] lg:w-[var(--cols-6)] lg:pt-spacing-06-desktop"
              :class="[!media ? 'lg:ml-[var(--push-6)]' : '']"
              >{{ trumpet }}</ui-font-text
            >
            <ui-font-text
              v-if="headline"
              class="ml-0 lg:w-[var(--cols-12)] lg:w-[var(--cols-5)]"
              type="h2"
              :class="[
                {
                  'w-[var(--cols-9)]': !isHero,
                },
                !media ? 'lg:ml-[var(--push-6)]' : '',
                body ? 'mb-spacing-06 lg:mb-spacing-06-desktop' : '',
                !trumpet ? 'pt-spacing-06 lg:pt-spacing-06-desktop' : '',
              ]"
              >{{ headline }}</ui-font-text
            >
          </div>
          <Parallaxy
            v-if="media && isMobile"
            :speed="20"
            direction="opposite"
            class="mb-spacing-06 aspect-portrait h-fit w-[var(--cols-7)] shrink-0 sm:relative"
          >
            <div class="group relative aspect-portrait w-full overflow-hidden rounded-corner-02">
              <div
                v-if="mediaCaption"
                class="media-caption absolute bottom-0 left-0 z-[1] flex h-fit w-full p-spacing-03 text-focus-light"
              >
                <div class="caption mt-auto flex flex-col gap-1">
                  <ui-font-text type="h2" v-if="mediaCaption.title">
                    {{ mediaCaption.title }}
                  </ui-font-text>
                  <ui-font-text type="smallBody" v-if="mediaCaption.text">
                    {{ mediaCaption.text }}
                  </ui-font-text>
                </div>
              </div>
              <Parallaxy
                :speed="35"
                direction="opposite"
                class="relative aspect-portrait w-full overflow-hidden rounded-corner-02"
              >
                <component-element-media
                  ref="media"
                  class="media h-full"
                  :media="media"
                  :is-rounded="true"
                  :caption="mediaCaption"
                ></component-element-media>
              </Parallaxy>
            </div>
          </Parallaxy>
          <ui-rich-text
            v-if="body"
            :rte="body"
            :class="[
              !media ? 'w-full lg:ml-[var(--push-6)]' : '',
              cta ? 'mb-spacing-05 lg:mb-spacing-05-desktop' : '',
            ]"
            class="text-[var(--text-theme)] lg:ml-0 lg:w-[var(--cols-5)]"
          />
        </div>
        <ui-call-to-action
          v-if="cta"
          :cta="cta"
          color="bg-[var(--text-theme)]"
          :class="!media ? 'w-full lg:ml-[var(--push-6)]' : ''"
        ></ui-call-to-action>
      </div>
    </component>
  </section>
</template>

<style scoped lang="postcss">
.content {
  .media-caption {
    background: linear-gradient(
      180deg,
      rgba(33, 52, 56, 0) 0%,
      rgba(33, 52, 56, 0.67) 49.93%,
      #213438 100%
    );
  }
}
</style>
