<script setup>
import Parallaxy from '@lucien144/vue3-parallaxy'

const props = defineProps({
  hasSidesPadding: {
    type: Boolean,
    default: true,
  },
  trumpet: String,
  headline: String,
  anchorId: String,
  body: String,
  media: Object,
  cta: Object,
  currentTheme: String,
  hasBorder: {
    type: Boolean,
    default: true,
  },
  isHero: {
    type: Boolean,
    default: false,
  },
})

const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 1024
})

const bleedCompoenent = resolveComponent('ui-bleed')
provide('show-video-controls', true)
</script>

<template>
  <section
    class="content"
    :class="{
      'border-t': hasBorder,
      'border-stroke-light': props.currentTheme === 'black' || props.currentTheme === 'dark-green',
      'border-stroke-dark': props.currentTheme !== 'black' || props.currentTheme !== 'dark-green',
    }"
    ref="content"
    :id="anchorId"
  >
    <component
      :is="hasSidesPadding ? bleedCompoenent : 'div'"
      class="gap-spacing-14 relative z-[var(--z-index-content-over-drop)] py-spacing-06 lg:grid-cols-2 lg:gap-[var(--grid-gap)] lg:py-spacing-06-desktop"
      :class="`${!trumpet && !body && !headline && !cta?.label ? '' : 'grid grid-cols-1'}`"
    >
      <Parallaxy
        v-if="media && !isMobile"
        :speed="40"
        direction="opposite"
        class="h-auto shrink-0 sm:relative lg:mb-spacing-06-desktop"
        :class="`${!trumpet && !body && !headline && !cta?.label ? 'w-full' : 'w-[var(--cols-6)] lg:ml-[var(--push-1)] lg:w-[var(--cols-4)]'}`"
      >
        <div class="relative w-full overflow-hidden rounded-corner-02 lg:rounded-corner-02-desktop">
          <component-element-media
            ref="media"
            class="media h-full"
            :media="media"
            :is-rounded="true"
          ></component-element-media>
        </div>
      </Parallaxy>

      <div ref="contentWrapper" class="content-wrapper relative flex flex-col">
        <div class="content-wrapper-inner relative">
          <div class="heading flex flex-col gap-spacing-07 lg:gap-spacing-07-desktop">
            <ui-font-text
              v-if="trumpet"
              type="label"
              class="ml-[var(--push-3)] w-[var(--cols-6)] pt-spacing-06 lg:ml-0 lg:w-[var(--cols-6)] lg:pt-spacing-06-desktop"
              :class="[!media ? 'lg:ml-[var(--push-6)]' : '']"
              >{{ trumpet }}</ui-font-text
            >
            <ui-font-text
              v-if="headline"
              class="ml-0 lg:w-[var(--cols-5)]"
              type="h2"
              :class="[
                {
                  'w-[var(--cols-9)]': !isHero,
                },
                !media ? 'lg:ml-[var(--push-6)]' : '',
                body ? 'mb-spacing-06 lg:mb-spacing-06-desktop' : '',
                !trumpet ? 'pt-spacing-06 lg:pt-spacing-06-desktop' : '',
              ]"
              >{{ headline }}</ui-font-text
            >
          </div>
          <Parallaxy
            v-if="media && isMobile"
            :speed="20"
            direction="opposite"
            class="mb-spacing-06 h-auto w-full shrink-0 overflow-clip rounded-corner-02 sm:relative"
          >
            <component-element-media
              ref="media"
              class="media h-full"
              :media="media"
              :is-rounded="true"
            ></component-element-media>
          </Parallaxy>
          <ui-rich-text
            v-if="body"
            :rte="body"
            :class="[
              !media ? 'w-full lg:ml-[var(--push-6)]' : '',
              cta ? 'mb-spacing-05 lg:mb-spacing-05-desktop' : '',
            ]"
            class="text-[var(--text-theme)] lg:ml-0 lg:w-[var(--cols-5)]"
          />
        </div>
        <ui-call-to-action
          v-if="cta"
          :cta="cta"
          color="bg-[var(--text-theme)]"
          :class="!media ? 'w-full lg:ml-[var(--push-6)]' : ''"
        ></ui-call-to-action>
      </div>
    </component>
  </section>
</template>
