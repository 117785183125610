<template>
  <section
    class="flipping-cards h-full py-spacing-04 lg:py-spacing-06"
    ref="cardsContainer"
    :class="{
      'pb-spacing-06 pt-spacing-04 lg:pb-spacing-06-desktop lg:pt-spacing-04-desktop': isBenefits,
    }"
  >
    <ui-bleed class="h-full">
      <div
        class="desktop-flip-cards flex h-full flex-col gap-[var(--grid-bleed)] lg:flex-row lg:gap-[var(--grid-gap)]"
        v-if="!isMobile"
      >
        <component-element-flip-card
          v-for="(card, index) in cards"
          :key="index"
          :card="card"
          :style="`--card-index:${index};--cards-length:${cards.length};--card-push:${index % 2 ? 'var(--push-3)' : ''};`"
          :class="{ 'lg:aspect-square': cards.length < 3 }"
          class="card w-full will-change-transform lg:ml-0 lg:mt-[calc(10rem_*_var(--card-index))] lg:w-[calc(var(--cols-12)/var(--cards-length))]"
        ></component-element-flip-card>
      </div>
      <div
        class="mobile-flip-cards flex h-full flex-col gap-[var(--grid-bleed)] lg:flex-row lg:gap-[var(--grid-gap)]"
        v-else
      >
        <component-element-flip-card-mobile
          v-for="(card, index) in cards"
          :key="index"
          :card="card"
          :style="`--card-index:${index};--cards-length:${cards.length};--card-push:${index % 2 ? 'var(--push-3)' : ''};`"
          class="card w-full will-change-transform lg:ml-0 lg:mt-[calc(10rem_*_var(--card-index))] lg:w-[calc(var(--cols-12)/var(--cards-length))]"
        ></component-element-flip-card-mobile>
      </div>
    </ui-bleed>
  </section>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})
const props = defineProps({
  cards: Array,
  isBenefits: Boolean,
})

const cardsContainer = ref(null)
let scrollTriggers = []

onMounted(() => {
  const cardsElements = cardsContainer.value.querySelectorAll('.card')
  if (isMobile.value) return
  cardsElements.forEach((card, index) => {
    const trigger = gsap.to(card, {
      yPercent: -10 * index,
      ease: 'power2.inOut',
      scrollTrigger: {
        trigger: cardsContainer.value,
        scrub: true,
        // markers: true,
      },
    }).scrollTrigger

    scrollTriggers.push(trigger)
  })
})

onUnmounted(() => {
  setTimeout(() => {
    scrollTriggers.forEach((trigger) => trigger.kill())
    scrollTriggers = []
  }, 1000)
})
</script>
