<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  medias: Array,
})
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})
const mediasContainer = ref(null)
const leftMedia = ref(null)
const rightMedia = ref(null)
const mediaHeight = ref(0)
const hasTwoMedias = computed(() => props.medias.length === 2)
let scrollTriggers = []

onMounted(() => {
  const mediasElements = mediasContainer.value.querySelectorAll('.media')
  if (isMobile.value) return
  mediasElements.forEach((media, index) => {
    const trigger = gsap.to(media, {
      yPercent: -10 * index,
      ease: 'power2.inOut',
      scrollTrigger: {
        trigger: mediasContainer.value,
        scrub: true,
        // markers: true,
      },
    }).scrollTrigger

    scrollTriggers.push(trigger)
  })

  mediaHeight.value = mediasElements[0]?.clientHeight
})

onUnmounted(() => {
  setTimeout(() => {
    scrollTriggers.forEach((trigger) => trigger.kill())
    scrollTriggers = []
  }, 1000)
})

provide('isMediaSection', true)
</script>

<template>
  <section class="medias-section h-full" ref="mediasContainer">
    <ui-bleed
      class="flex flex-col gap-[calc(var(--grid-bleed)*2)] pb-[var(--grid-bleed)] pt-[var(--grid-bleed)] lg:flex-row lg:gap-[var(--grid-gap)]"
    >
      <component-element-media
        v-if="medias[0]"
        :media="medias[0]"
        :is-rounded="true"
        ref="leftMedia"
        class="left-media"
        :class="
          !hasTwoMedias
            ? 'aspect-portrait lg:aspect-landscape lg:w-[var(--cols-12)]'
            : 'aspect-portrait lg:w-[var(--cols-4)]'
        "
      ></component-element-media>
      <component-element-media
        v-if="medias[1]"
        :media="medias[1]"
        :is-rounded="true"
        ref="rightMedia"
        :style="`--media-height: ${mediaHeight / 2}px`"
        class="right-media aspect-landscape lg:w-[var(--cols-8)]"
      ></component-element-media>
    </ui-bleed>
  </section>
</template>
