<script setup>
const props = defineProps({
  cards: Array,
});
const cardStack = ref(null);
const scrollPastCards = () => {
  const lastCard = cardStack.value.querySelector(".last-card");
  if (lastCard && window) {
    const stickyOffset = parseFloat(getComputedStyle(lastCard).top);
    const offsetTop =
      lastCard.getBoundingClientRect().top +
      lastCard.getBoundingClientRect().height +
      window.scrollY -
      stickyOffset;

    window.scrollTo({ top: offsetTop, behavior: "smooth" });
  }
};
</script>

<template>
  <section class="card-stack relative" ref="cardStack">
    <ui-bleed>
      <component-element-stack-card
        v-for="(card, index) in cards"
        :key="index"
        :card="card"
        :index="index"
        :cards-length="cards.length"
        @skip="scrollPastCards"
      />
    </ui-bleed>
  </section>
</template>
