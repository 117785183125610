<script setup>
import Parallaxy from '@lucien144/vue3-parallaxy'

const props = defineProps({
  headline: String,
  contentTrumpet: String,
  contentHeadline: String,
  contentText: String,
  contentCallToAction: Object,
  media: Object,
})
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 1280
})

const headlineEl = ref(null)
const headlineHeight = computed(() =>
  isMobile.value ? headlineEl.value?.offsetHeight + 64 : headlineEl.value?.offsetHeight,
)
const mediaHero = ref(null)
const { currentScroll } = useSmoothScroll()
const progress = ref(0)
const opacityProgress = ref(0.5)
onMounted(() => {
  if (!mediaHero.value) return

  const updateProgress = () => {
    if (!mediaHero.value) return

    const scrollY = window.scrollY
    const windowHeight = window.innerHeight

    const mediaHeroRect = mediaHero.value.getBoundingClientRect()
    const mediaHeroBottom = mediaHeroRect.bottom + scrollY
    const mediaHeroTop = mediaHeroRect.top + scrollY

    // Start scrolling when the top of mediaHero is in the viewport
    const startScrollPosition = 0

    // Progress reaches 1 when the bottom of mediaHero reaches the top of the viewport
    const endScrollPosition = mediaHeroBottom

    const maxScroll = endScrollPosition - startScrollPosition

    progress.value = (currentScroll.value - startScrollPosition) / maxScroll
    progress.value = Math.min(Math.max(progress.value, 0), 1)
    opacityProgress.value = (currentScroll.value - startScrollPosition) / maxScroll
    opacityProgress.value = Math.min(Math.max(progress.value * 0.5 + 0.5, 0.5), 1)
  }

  // Watch the scroll and update progress
  watch(currentScroll, updateProgress)
})
</script>

<template>
  <section
    class="hero relative h-full overflow-clip bg-[var(--background-theme)] text-[var(--text-theme)]"
    :style="`margin-top:${-headlineHeight}px;`"
    ref="mediaHero"
  >
    <div class="background-media sticky left-0 top-0 h-screen w-full overflow-hidden">
      <div
        class="background-layer absolute left-0 top-0 z-[1] h-full w-full bg-[var(--background-theme)]"
        :style="`opacity:${opacityProgress}`"
      ></div>
      <Parallaxy :speed="0" v-if="media" class="h-full w-full">
        <div class="clip flex h-full justify-center overflow-hidden">
          <Parallaxy :speed="35" class="h-[110%] min-w-[100vw] origin-center ultra:h-[100vw]">
            <div class="fade-layer absolute left-0 top-0 z-[1] h-full w-full"></div>
            <component-element-media
              class="media h-[120%]"
              :style="`transform:scale(${1 + progress / 7})`"
              v-if="media"
              :media="media"
            ></component-element-media>
          </Parallaxy>
        </div>
      </Parallaxy>
    </div>
    <ui-bleed class="content absolute h-full">
      <div class="heading-wrapper flex pb-spacing-06 lg:pb-spacing-06-desktop" ref="headlineEl">
        <ui-font-text
          class="heading z-[1] indent-[calc(var(--push-3))] lg:indent-[calc(var(--push-6))]"
          type="h1"
          >{{ headline }}</ui-font-text
        >
      </div>
      <section-content
        :has-sides-padding="false"
        :has-border="false"
        :trumpet="contentTrumpet"
        :headline="contentHeadline"
        :body="contentText"
        :cta="contentCallToAction"
        :is-hero="true"
      />
    </ui-bleed>
  </section>
</template>

<style>
.fade-layer {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
</style>
