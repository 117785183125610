<script setup>
const props = defineProps({
  persons: Array,
  countLabel: String,
})

const containerRef = ref(null)
const fieldsRef = ref([])
const infosRef = ref([])
// const infosHeight = ref('auto') // Add infosHeight ref

const angle = ref(0)
const scroll = ref(0)
let velocity = 0
const friction = 0.875
const snapSpeed = 0.1
let isDragging = false
let snapping = false
let startX = 0
let snapTarget = 0

const step = computed(() => {
  return (2 * Math.PI) / props.persons.length
})
const activeAngle = 1.5 * Math.PI

onMounted(() => {
  const container = containerRef.value
  const { width, height } = container.getBoundingClientRect()
  const radius = 200

  function positionFields() {
    const maxAngle = 2 * Math.PI

    let closestAngle = Infinity
    let closestIndex = -1

    fieldsRef.value.forEach((field, index) => {
      const rect = field.getBoundingClientRect() // Get the field's dimensions
      const fieldAngle = index * step.value

      // Calculate the x and y positions based on the angle and radius
      const x = Math.round(
        width / 2 + radius * Math.cos(fieldAngle + scroll.value) - rect.width / 2,
      )
      const y =
        Math.round(height / 2 + radius * Math.sin(fieldAngle + scroll.value) - rect.height / 2) / 2

      // Apply the calculated positions to the field
      field.style.transform = `translate(${x}px, ${y}px)`

      const realAngle = (((fieldAngle + scroll.value) % maxAngle) + maxAngle) % maxAngle

      const active = Math.abs(realAngle - activeAngle) <= step.value / 2
      field.classList.toggle('active', active)
      infosRef.value[index].classList.toggle('active', active)

      // Find the closest angle
      const distance = Math.abs(realAngle - activeAngle)
      if (distance < closestAngle) {
        closestAngle = distance
        closestIndex = index
      }
    })

    // Set infosHeight based on the active .infos element
    // nextTick(() => {
    //   const activeInfo = infosRef.value.find((info) => info.classList.contains('active'))
    //   if (activeInfo) {
    //     infosHeight.value = `${activeInfo.getBoundingClientRect().height}px`
    //   }
    // })
  }

  function update() {
    if (!isDragging) {
      velocity *= friction
      scroll.value += velocity

      if (snapping) {
        const difference = snapTarget - scroll.value
        if (Math.abs(difference) < 0.001) {
          scroll.value = snapTarget
          snapping = false
        } else {
          scroll.value += difference * snapSpeed
        }
      }

      positionFields()
    }
    requestAnimationFrame(update)
  }

  function handlePointerDown(e) {
    isDragging = true
    startX = e.clientX
    velocity = 0
    snapping = false
  }

  function handlePointerMove(e) {
    if (!isDragging) return
    const deltaX = e.clientX - startX
    velocity = deltaX * 0.0025
    scroll.value += velocity
    startX = e.clientX

    positionFields()
  }

  function handlePointerUp() {
    isDragging = false
    const predictedScroll = scroll.value + velocity / (1 - friction)
    snapTarget = Math.round((predictedScroll + angle.value) / step.value) * step.value - angle.value

    // Find the closest index
    let closestIndex = -1
    let closestAngle = Infinity
    fieldsRef.value.forEach((field, index) => {
      const fieldAngle = index * step.value
      const realAngle =
        (((fieldAngle + scroll.value) % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI)
      const distance = Math.abs(realAngle - activeAngle)
      if (distance < closestAngle) {
        closestAngle = distance
        closestIndex = index
      }
    })

    snapping = true

    handleFieldClick(closestIndex)
  }

  function handleFieldClick(index) {
    const fieldAngle = index * step.value
    let targetScroll = activeAngle - fieldAngle
    targetScroll = ((targetScroll - scroll.value + Math.PI) % (2 * Math.PI)) - Math.PI
    snapTarget = scroll.value + targetScroll
    snapping = true
  }

  container.addEventListener('pointerdown', handlePointerDown)
  container.addEventListener('pointermove', handlePointerMove)
  container.addEventListener('pointerup', handlePointerUp)
  container.addEventListener('pointerleave', () => {
    isDragging = false
  })

  fieldsRef.value.forEach((field, index) => {
    field.addEventListener('click', () => handleFieldClick(index))
  })
  handleFieldClick(0)
  nextTick(update)
})

const personsPlaceholder = ref(null)
</script>

<template>
  <div class="people-slider">
    <div class="slider relative h-[200px]">
      <div
        ref="containerRef"
        id="container"
        class="relative h-[600px] w-screen touch-none select-none"
      >
        <!-- <div id="center"></div>
        <div id="crosshair-x"></div>
        <div id="crosshair-y"></div> -->
        <div
          class="field h-spacing-06 w-spacing-06 overflow-hidden rounded-full"
          v-for="(people, index) in persons"
          :key="index"
          ref="fieldsRef"
        >
          <component-element-media
            class="h-full w-full object-cover"
            v-if="people.media"
            :media="people.media"
          ></component-element-media>
        </div>
      </div>
    </div>
    <ui-bleed class="people-infos lg:ml-[var(--push-6)]">
      <div
        v-for="(people, index) in persons"
        :key="index"
        ref="infosRef"
        class="infos absolute left-[var(--grid-bleed)] top-0 w-[calc(100vw-var(--grid-bleed)*2)] lg:w-[var(--cols-5)]"
      >
        <ui-font-text type="h2" class="pb-spacing-02" v-if="people.title">{{
          people.title
        }}</ui-font-text>
        <ui-font-text type="label" class="pb-spacing-04" v-if="people.position">{{
          people.position
        }}</ui-font-text>
        <ui-font-text type="body" class="pb-spacing-04" v-if="people.text">{{
          people.text
        }}</ui-font-text>
        <nuxt-link
          data-cursor="empty"
          v-if="people.linkedinUrl"
          class="block h-spacing-04 w-spacing-04"
          :to="people.linkedinUrl"
          :aria-label="`Linkedin ${people.title}`"
        >
          <NuxtIcon
            class="icon download-icon block h-spacing-04 w-spacing-04"
            name="linkedin"
            filled
        /></nuxt-link>
      </div>
    </ui-bleed>
    <ui-bleed
      class="people-infos absolute left-0 top-0 flex h-auto w-full opacity-0"
      ref="personsPlaceholder"
    >
      <div
        v-for="(people, index) in persons"
        :key="index"
        ref="infosRef"
        class="infos w-[calc(100vw-var(--grid-bleed)*2)] shrink-0"
      >
        <ui-font-text type="h2" class="pb-spacing-02" v-if="people.title">{{
          people.title
        }}</ui-font-text>
        <ui-font-text type="label" class="pb-spacing-04" v-if="people.position">{{
          people.position
        }}</ui-font-text>
        <ui-font-text type="body" class="pb-spacing-04" v-if="people.text">{{
          people.text
        }}</ui-font-text>
        <nuxt-link
          data-cursor="empty"
          v-if="people.linkedinUrl"
          class="block h-spacing-04 w-spacing-04"
          :to="people.linkedinUrl"
          :aria-label="`Linkedin ${people.title}`"
        >
          <NuxtIcon
            class="icon download-icon block h-spacing-04 w-spacing-04"
            name="linkedin"
            filled
        /></nuxt-link>
      </div>
    </ui-bleed>
  </div>
</template>

<style scoped>
.icon svg {
  width: 100%;
  height: 100%;
}
.slider {
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
}
.field {
  position: absolute;
  background: black;
  color: white;
  transition:
    background-color 0.3s ease-in-out,
    height 0.3s ease-in-out,
    width 0.3s ease-in-out;
}

.field.active {
  background: red;
  width: 100px;
  height: 100px;
}

.people-infos {
  display: flex;
  flex-direction: row;
  transition: height 0.2s ease-in-out;
  /* min-height: 250px; */
}
.infos {
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
.infos.active {
  opacity: 1;
}

.slider::after {
  pointer-events: none;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, transparent 0%, var(--background-theme) 45%);
  z-index: 2;
}
.slider::before {
  pointer-events: none;

  z-index: 1;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: var(--background-theme);
}

#center {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 295px;
  top: 295px;
  background: #000;
}

#crosshair-x {
  width: 100vw;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  top: 300px;
}

#crosshair-y {
  width: 1px;
  height: 100vw;
  background: #000;
  position: absolute;
  left: 300px;
  top: 0;
}
</style>
