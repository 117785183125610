<script setup>
import Parallaxy from '@lucien144/vue3-parallaxy'
import { gsap } from 'gsap'
const props = defineProps({
  card: Object,
  index: Number,
  cardsLength: Number,
})
const emit = defineEmits(['skip'])

const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 1280
})

const randomParallaxes = ref([])
const background = ref(null)
const cardEl = ref(null)

const mouseX = ref(0)
const mouseY = ref(0)

function handleMouseMove(event) {
  if (isMobile.value) return
  if (props.card.foregroundMedias.length < 1) return
  const items = background.value.querySelectorAll('.media:not(.no-parallax)')
  const cardRect = cardEl.value.getBoundingClientRect()
  mouseX.value = event.clientX - cardRect.left - cardRect.width / 2
  mouseY.value = event.clientY - cardRect.top - cardRect.height / 2
  items.forEach((item, index) => {
    const animationFactor = items[index].getAttribute('data-parallax')
    gsap.to(item, {
      x: -mouseX.value * animationFactor,
      y: -mouseY.value * animationFactor,
      duration: 0.75,
    })
  })
}

onMounted(() => {
  if (props.card.foregroundMedias.length < 1) return
  randomParallaxes.value = Array.from({ length: props.card.foregroundMedias.length }, () =>
    getRandomFloatBetween(0.04, 0.1, 2),
  )
})
</script>

<template>
  <div
    :class="`${index === cardsLength - 1 ? 'last-card' : ''}`"
    class="card sticky top-[calc((var(--header-height-mobile)+var(--header-top)))] z-[var(--z-index-content-over-drop)] h-[calc(100vh-calc(1.5*(var(--header-height-mobile))))] w-full scroll-mt-[[calc((var(--header-height-mobile)+var(--header-top)))]] lg:top-[calc(1.5*(var(--header-height-mobile)+var(--header-top)))] lg:h-[calc(100vh-calc(1.5*(var(--header-height))))]"
  >
    <div
      class="card-content relative flex h-[calc(100vh-calc(1.5*(var(--header-height-mobile)+var(--header-top))))] w-full flex-col gap-[calc(var(--push-1)+var(--grid-gap))] overflow-clip rounded-corner-02 border border-stroke-light bg-dark-green lg:h-[calc(100vh-calc(2*(var(--header-height)+var(--header-top)*2)))] lg:flex-row lg:rounded-corner-02-desktop"
    >
      <ui-font-text
        type="label"
        class="absolute left-[var(--grid-bleed)] top-[var(--grid-bleed)]"
        >{{ index + 1 }}</ui-font-text
      >

      <ui-call-to-action
        class="absolute right-[20px] top-[20px] z-[calc(var(--z-index-content-over-drop)+1)] lg:right-[40px] lg:top-[40px]"
        :button="true"
        button-label="Skip"
        @click="emit('skip')"
        color="bg-ivory"
        data-cursor="arrow-down"
      >
      </ui-call-to-action>

      <div
        class="md-[40px] mr-auto flex h-[50%] shrink-0 p-[20px] lg:mt-auto lg:h-full lg:w-[calc(var(--cols-5)-var(--grid-gap)*2)] lg:p-[var(--grid-bleed)]"
      >
        <div
          class="mt-spacing-04 flex flex-col gap-spacing-04 lg:mb-auto lg:mt-auto lg:gap-spacing-04-desktop"
        >
          <ui-font-text type="h3">{{ card.headline }}</ui-font-text>
          <client-only>
            <ui-font-text type="body"><div v-html="card.body"></div></ui-font-text
          ></client-only>
        </div>
      </div>
      <!-- <component-element-media
        class="media aspect-square w-full"
        v-if="card.backgroundMedia"
        :media="card.backgroundMedia"
        :is-rounded="true"
      ></component-element-media> -->
      <div
        ref="cardEl"
        @mousemove="handleMouseMove"
        class="parallax-medias-wrapper relative h-full w-full overflow-hidden lg:aspect-square"
      >
        <client-only>
          <div
            class="medias-wrapper pointer-events-none absolute left-0 top-0 z-[1] h-full w-full"
            ref="background"
            v-if="!isMobile && card.foregroundMedias.length >= 1"
          >
            <div class="overlay-layer absolute left-0 top-0 z-[1] h-full w-full"></div>

            <div
              class="parallax-media pointer-events-none absolute left-0 top-0 z-[2] flex h-full w-full items-center justify-center"
              v-for="(media, index) in card.foregroundMedias"
              :key="index"
            >
              <component-element-media
                v-if="media"
                ref="media"
                class="media-hero aspect-square h-[120%] w-auto object-cover"
                :media="media"
                :data-parallax="randomParallaxes[index]"
              ></component-element-media>
            </div>
            <div
              v-if="card.backgroundMedia"
              class="background pointer-events-none absolute left-0 top-0 z-[1] flex h-full w-full origin-center items-center justify-center object-cover"
            >
              <component-element-media
                v-if="!isMobile && card.backgroundMedia"
                ref="media"
                class="media-hero no-parallax h-full w-full origin-center object-cover lg:h-[120%] lg:w-[120%]"
                :media="card.backgroundMedia"
                :data-parallax="0.01"
              ></component-element-media>
            </div>
          </div>
          <div v-else-if="isMobile" class="absolute left-0 top-0 h-full w-full">
            <div
              class="foreground-medias pointer-events-none absolute left-0 top-0 z-[2] flex h-full w-full items-center justify-center"
              v-for="(media, index) in card.foregroundMedias"
              :key="index"
            >
              <Parallaxy :speed="0" class="h-full w-full">
                <div class="clip h-full overflow-hidden">
                  <Parallaxy
                    :speed="card.foregroundMedias.length > 1 ? randomParallaxes[index] * 1000 : 20"
                    class="h-[110%] origin-center"
                  >
                    <component-element-media
                      class="media h-[120%]"
                      v-if="media"
                      :media="media"
                    ></component-element-media>
                  </Parallaxy>
                </div>
              </Parallaxy>
            </div>
            <div
              v-if="card.backgroundMedia"
              class="background pointer-events-none absolute left-0 top-0 z-[1] flex h-full w-full origin-center items-center justify-center object-cover"
            >
              <Parallaxy :speed="0" class="h-full w-full">
                <div class="clip h-full overflow-hidden">
                  <Parallaxy :speed="20" class="h-[110%] origin-center">
                    <component-element-media
                      ref="media"
                      class="media-hero h-[120%] w-full"
                      :media="card.backgroundMedia"
                    ></component-element-media>
                  </Parallaxy>
                </div>
              </Parallaxy>
            </div>
          </div>
        </client-only>
      </div>
    </div>
  </div>
</template>
