export default input => {
    let parsedTheme = '';
    let parsedStyle = '';
    let parsedDropStyle = { top: null, bottom: null };

    const themeNames = ['white', 'black', 'green', 'dark-green', 'ivory', 'dark-green-footer'];

    let theme = {};
    if (typeof input === 'string') {
        // Fetch the theme based on the slug
        switch (input) {
            case 'black':
                theme = { slug: 'black', background: '#000000', text: '#e4d7d0', dropTop: '#E4D7D0', dropBottom: "#8C8683" };
                break;
            case 'dark-green':
                theme = { slug: 'dark-green', background: '#213438', text: '#e4d7d0', dropTop: '#E4D7D0', dropBottom: "#8C8683" };
                break;
            case 'green':
                theme = { slug: 'green', background: '#446266', text: '#e4d7d0', dropTop: '#E4D7D0', dropBottom: "#5D7F80" };
                break;
            case 'ivory':
                theme = { slug: 'ivory', background: '#e4d7d0', text: '#213438', dropTop: '#21343', dropBottom: "#446266" };
                break;
            case 'white':
                theme = { slug: 'white', background: '#ffffff', text: '#213438', dropTop: '#213438', dropBottom: "#5D7F80" };
                break;
            case 'dark-green-footer':
                theme = { slug: 'black', background: '#000000', text: '#e4d7d0', dropTop: '#E4D7D0', dropBottom: "#8C8683" };
                break;
            default:
                throw new Error('Invalid theme slug');
        }
    } else if (typeof input === 'object' && themeNames.includes(input.slug)) {
        theme = input;
    } else {
        throw new Error('Invalid theme input');
    }

    if (themeNames.includes(theme.slug)) {
        parsedStyle = `--background-theme: ${theme.background}; --text-theme: ${theme.text};--drop-top-theme: ${theme.dropTop}; --drop-bottom-theme: ${theme.dropBottom}`;
        parsedTheme = 'bg-[var(--background-theme)] text-[var(--text-theme)]';

        parsedDropStyle.top = theme.dropTop
        parsedDropStyle.bottom = theme.dropBottom

    }

    return {
        parsedTheme,
        parsedStyle,
        parsedDropStyle
    };
}
