<script setup>
const props = defineProps({
  type: String,
  data: Object,
  idx: Number,
  id: Number,
  currentTheme: String,
})

const components = {
  // defaults
  hero: resolveComponent('section-hero'),
  heroMedia: resolveComponent('section-hero-media'),
  contentSection: resolveComponent('section-content'),
  cardsStack: resolveComponent('section-card-stack'),
  benefits: resolveComponent('section-benefits'),
  flippingCards: resolveComponent('section-flipping-cards'),
  historicEvents: resolveComponent('section-historic-events'),
  linkCards: resolveComponent('section-link-cards'),
  callout: resolveComponent('section-callout'),
  peoples: resolveComponent('section-peoples'),
  articlesList: resolveComponent('section-articles-list'),
  jobsList: resolveComponent('section-articles-list'),
  stepContent: resolveComponent('section-step-content'),
  mediaSection: resolveComponent('section-media'),
  mediaFit: resolveComponent('section-media-fit'),
}

const { parsedTheme, parsedStyle } = useTheme(props.data.theme)
</script>

<template>
  <!-- <component-behavior-in-view :style="parsedStyle" :class="parsedTheme"> -->
  <div :style="parsedStyle" :class="parsedTheme">
    <component
      class="section overflow-x-clip"
      :is="components[type]"
      v-bind="data"
      :current-theme="currentTheme"
    />
  </div>
  <!-- </component-behavior-in-view> -->
</template>
