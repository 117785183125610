<script setup>
import { gsap } from 'gsap'
const props = defineProps({
  articles: Array,
})
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 1280
})

const articlesList = ref(null)
const articlesMedias = ref(null)
const imageSources = computed(() => {
  return props.articles.map((article) => {
    return article.media
  })
})
const preview1 = ref(null)
const closestEdge = (x, y, w, h) => {
  const topEdgeDist = distMetric(x, y, w / 2, 0)
  const bottomEdgeDist = distMetric(x, y, w / 2, h)

  const min = Math.min(topEdgeDist, bottomEdgeDist)
  return min === topEdgeDist ? 'top' : 'bottom'
}

// Distance Formula
const distMetric = (x, y, x2, y2) => {
  const xDiff = x - x2
  const yDiff = y - y2
  return xDiff * xDiff + yDiff * yDiff
}

// Calculate closest edge
function findClosestEdge(ev) {
  const x = ev.pageX - ev.target.offsetLeft
  const y = ev.pageY - ev.target.offsetTop
  return closestEdge(x, y, ev.target.clientWidth, ev.target.clientHeight)
}

const mouseEnter = (ev, src) => {
  if (!src) return
  const edge = findClosestEdge(ev)
  const preview1 = articlesMedias.value?.querySelector('.preview-img-1')
  if (!preview1) return
  const img1 = document.createElement('img')
  const img2 = document.createElement('img')

  img1.src = src.attributes.src
  img1.srcset = src.attributes.srcset
  img2.src = src.attributes.src
  img2.srcset = src.attributes.srcset

  preview1.appendChild(img1)
  gsap.set([img1, img2], {
    clipPath:
      edge === 'top'
        ? 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)'
        : 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
    opacity: 0,
  })
  gsap.to([img1, img2], {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    opacity: 1,
    duration: 0.35,
    onComplete: function () {
      removeExtraImages(preview1)
    },
  })
}

function removeExtraImages(container) {
  while (container.children.length > 10) {
    container.removeChild(container.firstChild)
  }
}

function mouseLeave(ev, articleIndex) {
  const edge = findClosestEdge(ev)
  gsap.to('.preview-img img', {
    clipPath:
      edge === 'top'
        ? 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)'
        : 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
    opacity: 0,
    duration: 0.75,
    ease: 'power3.out',
  })
}

// Function to translate articlesMedias when mouse moves
function handleMouseMove(event) {
  if (!articlesList.value) return

  const articlesListHeight = articlesList.value?.clientHeight
  const mouseY = event.clientY - articlesList.value.getBoundingClientRect().top

  // Calculate translation percentage
  const translateY = ((mouseY / articlesListHeight) * 100 - 50) * 0.1 // Adjust the factor to control translation amount

  gsap.to(articlesMedias.value, {
    yPercent: translateY,
    duration: 0.6,
    ease: 'power2.out',
  })
}

// Attach the mousemove event listener
onMounted(() => {
  if (!isMobile.value) {
    articlesList.value.addEventListener('mousemove', handleMouseMove)
  }
})

onUnmounted(() => {
  if (!isMobile.value) {
    articlesList.value?.removeEventListener('mousemove', handleMouseMove)
  }
})
</script>

<template>
  <div
    class="articles-list"
    :class="{
      'flex flex-row gap-[var(--grid-gap)] px-[var(--grid-bleed)]': !isMobile,
    }"
    ref="articlesList"
  >
    <div
      class="medias-wrapper preview relative ml-[var(--push-1)] w-[var(--cols-2)]"
      v-if="!isMobile"
    >
      <div
        ref="articlesMedias"
        class="medias absolute left-0 top-0 aspect-square h-auto w-full origin-center overflow-hidden rounded-corner-02-desktop"
      >
        <div
          class="preview-img-1 preview-img absolute left-0 top-0 aspect-square h-auto w-full origin-center"
        ></div>
      </div>
    </div>
    <ul
      class="flex flex-col gap-[var(--grid-gap)] lg:gap-0"
      :class="{ 'w-[var(--cols-9)]': !isMobile }"
    >
      <li
        v-for="(article, articleIndex) in articles"
        :key="articleIndex"
        class="relative lg:pb-[var(--grid-gap)]"
        @mouseenter="(ev) => mouseEnter(ev, imageSources[articleIndex])"
        @mouseleave="(ev) => mouseLeave(ev, articleIndex)"
      >
        <span
          class="mb-[var(--grid-gap)] ml-[var(--grid-bleed)] block h-[1px] w-[calc(100vw-2*var(--grid-bleed))] bg-stroke-dark xl:ml-0 xl:w-[var(--cols-9)]"
        ></span>

        <nuxt-link
          data-cursor="empty"
          aria-label="Read article"
          :to="article.meta.url"
          class="ml-[var(--grid-bleed)] flex w-[calc(100vw-var(--grid-bleed)*2)] flex-row items-start gap-[var(--grid-gap)] lg:ml-0 lg:min-h-[var(--cols-1)] lg:w-full lg:items-center xl:gap-[calc(var(--push-1)+var(--grid-gap))]"
        >
          <div
            v-if="isMobile"
            class="media-wrapper aspect-square w-[var(--cols-3)] shrink-0 overflow-visible lg:ml-[var(--grid-bleed)] lg:w-[var(--cols-2)] xl:ml-[calc(var(--push-1)+var(--grid-bleed))] xl:w-[var(--cols-1)]"
          >
            <component-element-media
              class="media aspect-square h-auto"
              v-if="isMobile && article.media"
              :class="{
                'absolute left-0 top-1/2 w-[var(--cols-2)] origin-center -translate-y-1/2 lg:w-full ultra:w-[calc(var(--cols-2)-var(--grid-bleed)*4)]':
                  !isMobile,
              }"
              :media="article.media"
              :is-rounded="true"
            ></component-element-media>
          </div>
          <div
            class="content relative flex w-full flex-col-reverse gap-spacing-03 align-middle lg:flex-row lg:gap-[calc(var(--push-2)+var(--grid-gap))] lg:align-top xl:gap-[calc(var(--push-2)+var(--grid-gap))]"
          >
            <ui-font-text
              type="smallBody"
              class="text-dark-ivory lg:w-[var(--cols-2)] lg:shrink-0 xl:w-[var(--cols-1)]"
              >{{ formatDate(article.meta.postDate) }}</ui-font-text
            >
            <ui-font-text
              type="h4Body"
              class="w-[var(--cols-5)] text-ellipsis lg:w-[var(--cols-6)] ultra:w-[var(--cols-5)]"
              >{{ article.title }}</ui-font-text
            >
          </div>
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.medias {
  will-change: transform;
}

.preview-img-1:deep(img) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  opacity: 0;
  left: 0;
}
</style>
