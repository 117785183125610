<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Vue3Lottie } from 'vue3-lottie'

gsap.registerPlugin(ScrollTrigger)

const router = useRouter()
const route = useRoute()
const { globalData, pageIsTransitioning } = useVandState()
const isOpen = ref(false)
const openMenu = () => {
  isOpen.value = !isOpen.value
}
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})

const navBar = ref(null)
const parsedTheme = ref(null)
const parsedStyle = ref(null)
const currentTheme = ref(null)

const { lenis } = useSmoothScroll()

const lenisScrollTo = (scrollValue) => {
  lenis.value.scrollTo(scrollValue, {
    duration: 1,
    easing: (t) => {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
  })
}
let scrollTriggers = []

const initScrollTriggers = () => {
  // console.log("initScrollTriggers");
  cleanScrollTriggers()
  const sections = document.querySelectorAll('[data-theme]')
  if (!sections) return
  sections.forEach((section) => {
    const dataTheme = section.getAttribute('data-theme')
    const { parsedTheme: theme, parsedStyle: style, parsedDropStyle } = useTheme(dataTheme)
    const trigger = ScrollTrigger.create({
      trigger: section,
      start: `top top+=${isMobile.value ? '45px' : '68px'}`,
      end: `bottom top+=${isMobile.value ? '45px' : '68px'}`,
      scrub: 1,
      // markers: true,
      onEnter: () => {
        currentTheme.value = dataTheme
        parsedTheme.value = theme
        parsedStyle.value = style
        const lottieLogos = document.querySelectorAll('.lottie-wrapper')
        lottieLogos.forEach((lottieLogo) => {
          setTimeout(() => {
            const paths = lottieLogo.querySelectorAll('path:not(defs path)')
            // console.log('paths', paths)
            paths[0]?.setAttribute('fill', parsedDropStyle.bottom)
            paths[1]?.setAttribute('fill', parsedDropStyle.top)
          })
        })
      },
      onEnterBack: () => {
        currentTheme.value = dataTheme
        parsedTheme.value = theme
        parsedStyle.value = style
        const lottieLogos = document.querySelectorAll('.lottie-wrapper')
        lottieLogos.forEach((lottieLogo) => {
          setTimeout(() => {
            const paths = lottieLogo.querySelectorAll('path:not(defs path)')
            paths[0]?.setAttribute('fill', parsedDropStyle.bottom)
            paths[1]?.setAttribute('fill', parsedDropStyle.top)
          })
        })
      },
    })
    scrollTriggers.push(trigger)
  })
  const footer = document.querySelector('.footer')
  if (!isMobile.value) return
  const trigger = gsap.to(navBar.value, {
    // opacity: 0,
    y: isMobile.value ? '-65px' : '-95px',
    ease: 'none',
    scrollTrigger: {
      trigger: footer,
      scrub: true,
      start: `top top+=${isMobile.value ? '65px' : '95px'}`,
      end: 'top top',
      // markers: true,
    },
  }).scrollTrigger
  scrollTriggers.push(trigger)
}

const cleanScrollTriggers = () => {
  scrollTriggers.forEach((trigger) => trigger.kill())
  scrollTriggers = []
  // ScrollTrigger.refresh();
}

onMounted(() => {
  setTimeout(() => {
    initScrollTriggers()
  })
})

onUnmounted(() => {
  // console.log("unmounted header");
  cleanScrollTriggers()
})

watch(isOpen, () => {
  if (isOpen.value) {
    lenis.value.stop()
  } else {
    lenis.value.start()
  }
})

watch(pageIsTransitioning, () => {
  pageIsTransitioning.value ? cleanScrollTriggers() : initScrollTriggers()
})

watch(
  () => route.fullPath,
  () => {
    isOpen.value = false
  },
)

const openmodal = () => {
  useEvent('modal:open', {
    data: null,
    type: 'contact',
  })
}

const { introAnimationIsDone } = useVandState()

const lottieAnimation = ref(null)
const play = () => {
  lottieAnimation.value.play()
}

watch(
  introAnimationIsDone,
  () => {
    if (introAnimationIsDone.value && lottieAnimation.value) {
      play()
      initScrollTriggers()
    }
  },
  {
    immediate: true,
  },
)
</script>
<template>
  <nav
    ref="navBar"
    class="the-header fixed left-0 top-[var(--header-top-mobile)] z-[var(--z-index-header)] flex h-[var(--header-height-mobile)] w-full flex-row justify-between lg:top-[var(--header-top)] lg:h-[var(--header-height)]"
    :class="{ parsedTheme }"
    :style="parsedStyle"
  >
    <nuxt-link
      aria-label="Home"
      to="/"
      @click="router.currentRoute.value.fullPath === '/' ? lenisScrollTo(0) : null"
      class="logo lottie-logo relative h-full w-[32px] transition-all lg:w-[40px]"
      data-cursor="empty"
    >
      <div
        class="absolute left-0 top-[calc(var(--header-top-mobile)*-1.3)] block w-full transition-all lg:-top-[var(--header-top)]"
      >
        <client-only>
          <Vue3Lottie
            class="lottie-wrapper"
            ref="lottieAnimation"
            animation-link="/lottie/drop.json"
            :loop="false"
        /></client-only>
      </div>
    </nuxt-link>

    <div
      v-if="!isMobile"
      class="blurred flex h-full w-[var(--cols-6)] shrink-0 flex-row items-center gap-spacing-02 rounded-corner-02 border px-[var(--grid-gap)] backdrop-blur-[15px] transition-all lg:rounded-corner-02-desktop xl:gap-spacing-03-desktop"
      :class="{
        'border-stroke-light bg-nav-dark':
          currentTheme === 'black' ||
          currentTheme === 'dark-green' ||
          currentTheme === 'green' ||
          currentTheme === 'dark-green-footer',
        'border-stroke-dark bg-nav-light': currentTheme === 'white' || currentTheme === 'ivory',
      }"
    >
      <div class="mr-auto flex h-full gap-spacing-02 xl:gap-spacing-03-desktop">
        <nuxt-link
          data-cursor="empty"
          class="menu-item relative flex h-full overflow-hidden text-ellipsis whitespace-nowrap"
          :aria-label="item.title"
          :to="item.href"
          :target="item.target"
          v-for="item in globalData.header.data"
          :key="item.label"
        >
          <ui-menu-item class="mb-auto mt-auto">
            <ui-font-text type="label" class="relative h-full text-[var(--text-theme)]">
              <span class="mb-auto mt-auto h-fit">{{ item.title }}</span>
            </ui-font-text>
          </ui-menu-item>
          <span
            class="page-indicator absolute bottom-0 left-0 h-[1px] w-full bg-[var(--text-theme)]"
            :class="{
              'w-0 opacity-0':
                router.currentRoute.value.path !== item.href ||
                router.currentRoute.value.path === '/',
            }"
          ></span>
        </nuxt-link>
      </div>
      <button
        v-if="globalData?.contact?.showNewsletter"
        @click="openmodal"
        class="cursor-pointer"
        data-cursor="empty"
        aria-label="Contact us"
      >
        <ui-font-text
          type="label"
          class="ml-auto flex flex-row gap-2.5 text-[var(--text-theme)]"
          :style="parsedStyle"
        >
          <div class="menu-item flex flex-row gap-2.5">
            <ui-menu-item class="mb-auto mt-auto">
              <ui-font-text
                type="label"
                class="relative flex h-full flex-col gap-1.5 text-[var(--text-theme)]"
              >
                <span class="mb-auto mt-auto h-fit">contact us </span>
              </ui-font-text> </ui-menu-item
            ><NuxtIcon name="chevron-right" class="icon h-[10px] w-[10px] self-center"></NuxtIcon>
          </div>
        </ui-font-text>
      </button>
    </div>
    <div v-else>
      <div
        class="blurred h-[40px] w-[40px] rounded-corner-02-desktop border p-[10px] backdrop-blur-[15px]"
        :class="{
          'border-stroke-light':
            currentTheme === 'black' ||
            currentTheme === 'dark-green' ||
            currentTheme === 'green' ||
            currentTheme === 'dark-green-footer',
          'border-stroke-dark': currentTheme === 'white' || currentTheme === 'ivory',
        }"
        @click="openMenu"
      >
        <NuxtIcon name="dots-menu" class="icon" filled></NuxtIcon>
      </div>
      <globals-header-menu
        :menu="globalData.header.data"
        :parsedStyle="parsedStyle"
        :parsedTheme="parsedTheme"
        :currentTheme="currentTheme"
        class="mobile-menu"
        :class="{
          'is-open': isOpen,
        }"
        @isOpen="openMenu"
      ></globals-header-menu>
    </div>
  </nav>
</template>

<style scoped>
.blurred {
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.lottie-logo:deep(svg g path) {
  transition: all 0.2s ease-in-out;
}
.icon:deep(svg) {
  fill: var(--text-theme);
  color: var(--text-theme);
  width: 100%;
  height: 100%;
}
.menu-item:hover:deep(.original) {
  transform: translateY(200%);
  opacity: 0;
}
.menu-item:hover:deep(.copy) {
  transform: translateY(0%);
  opacity: 1;
}

.logo {
  transition: filter 0.3s ease-in-out;
}

.mobile-menu {
  transition:
    clip-path 0.3s ease-in-out,
    opacity 0.5s ease-in-out,
    backdrop-filter 0.3s ease-in-out;
  opacity: 0;
  backdrop-filter: blur(0px);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}
.mobile-menu.is-open {
  backdrop-filter: blur(40px);
  opacity: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.mobile-menu:deep(ul) li {
  overflow: hidden;
}
.mobile-menu:deep(ul) li p {
  transform: translateY(-100%);
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    transform 0.5s ease-in-out;
}
.page-indicator {
  transition: all 0.2s ease-in-out;
}
.mobile-menu.is-open:deep(ul) li p {
  transform: translateY(0);
  opacity: 1;
  transition-delay: var(--delay);
}
</style>
