<script setup>
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import emblaCarouselVue from 'embla-carousel-vue'
import ClassNames from 'embla-carousel-class-names'
const props = defineProps({
  events: Array,
  trumpet: String,
})

const [emblaRef, emblaApi] = emblaCarouselVue({ align: 'start' }, [
  WheelGesturesPlugin(),
  ClassNames(),
])
const [emblaThumbsRef, emblaThumbsApi] = emblaCarouselVue(
  {
    align: 'center',
    containScroll: 'keepSnaps',
    dragFree: true,
  },
  [WheelGesturesPlugin(), ClassNames()],
)

const addThumbBtnsClickHandlers = (emblaApi, emblaThumbsApi) => {
  // console.log('emblaApi', emblaApi, 'emblaThumbsApi', emblaThumbsApi)
  const slidesThumbs = emblaThumbsApi.slideNodes()

  const scrollToIndex = slidesThumbs.map((_, index) => () => emblaApi.scrollTo(index))

  slidesThumbs.forEach((slideNode, index) => {
    slideNode.addEventListener('click', scrollToIndex[index], false)
  })

  return () => {
    slidesThumbs.forEach((slideNode, index) => {
      slideNode.removeEventListener('click', scrollToIndex[index], false)
    })
  }
}

const addToggleThumbBtnsActive = (emblaApi, emblaThumbsApi) => {
  const slidesThumbs = emblaThumbsApi.slideNodes()

  const toggleThumbBtnsState = () => {
    emblaThumbsApi.scrollTo(emblaApi.selectedScrollSnap())
    const previous = emblaApi.previousScrollSnap()
    const selected = emblaApi.selectedScrollSnap()
    slidesThumbs[previous].classList.remove('embla-thumbs__slide--selected')
    slidesThumbs[selected].classList.add('embla-thumbs__slide--selected')
  }

  emblaApi.on('select', toggleThumbBtnsState)
  emblaThumbsApi.on('init', toggleThumbBtnsState)

  return () => {
    const selected = emblaApi.selectedScrollSnap()
    slidesThumbs[selected].classList.remove('embla-thumbs__slide--selected')
  }
}

onMounted(() => {
  addThumbBtnsClickHandlers(emblaApi.value, emblaThumbsApi.value)
  addToggleThumbBtnsActive(emblaApi.value, emblaThumbsApi.value)
})
onUnmounted(() => {
  addThumbBtnsClickHandlers(emblaApi.value, emblaThumbsApi.value)
  addToggleThumbBtnsActive(emblaApi.value, emblaThumbsApi.value)
})

const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 1280
})
</script>

<template>
  <section
    class="historic-events grid w-full gap-spacing-07 py-spacing-08 lg:gap-spacing-07-desktop lg:py-spacing-09-desktop"
  >
    <ui-bleed v-if="trumpet" class="ml-push-3 lg:ml-push-6">
      <ui-font-text type="label">{{ trumpet }}</ui-font-text>
    </ui-bleed>
    <div class="embla grid w-full">
      <div class="embla-thumbs relative w-full">
        <div
          class="embla-thumbs__viewport w-screen overflow-hidden pl-[calc(var(--push-3)+var(--grid-bleed))] pr-[calc(var(--cols-5)+var(--grid-bleed))] lg:w-screen lg:pl-[calc(var(--push-6)+var(--grid-bleed))] lg:pr-[calc(var(--cols-6))]"
          ref="emblaThumbsRef"
        >
          <div class="embla-thumbs__container flex w-full gap-spacing-06 lg:gap-spacing-08-desktop">
            <div
              class="embla-thumbs__slide flex w-fit shrink-0 justify-center lg:justify-normal"
              v-for="(event, index) in events"
              :key="index"
              :class="[
                index === 0 ? 'embla-thumbs__slide--selected' : '',
                // index === events.length - 1 ? 'pl-bleed lg:pl-0' : 'pl-bleed',
              ]"
            >
              <button
                type="button"
                data-cursor="empty"
                class="embla-thumbs__slide__content flex select-none flex-col items-center gap-spacing-02 lg:items-start lg:gap-spacing-02-desktop"
              >
                <ui-font-text type="body" class="year">{{ event.year }}</ui-font-text>
                <ui-font-text type="smallBody" class="month text-[var(--text-theme)] opacity-25">{{
                  event.month.label
                }}</ui-font-text>
                <div
                  class="ellipse mt-4 h-[10px] w-[10px] rounded-full bg-[var(--text-theme)] transition-all duration-300"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="line h-[1px] w-screen"></div>
      <div
        class="embla__viewport relative overflow-hidden pt-spacing-06 lg:pt-spacing-06-desktop"
        ref="emblaRef"
      >
        <div class="embla__container flex">
          <div
            v-for="(event, index) in events"
            :key="index"
            class="embla__slide min-w-0 overflow-hidden"
          >
            <div
              class="content grid w-cols-9 gap-spacing-04 lg:ml-push-6 lg:w-cols-6 lg:gap-spacing-04-desktop"
              :class="index === events.length - 1 ? 'pl-bleed' : 'pl-bleed'"
            >
              <ui-font-text type="h2">{{ event.title }}</ui-font-text>
              <ui-font-text type="smallBody" v-if="isMobile" class="w-full lg:w-cols-5">{{
                event.text
              }}</ui-font-text>
              <ui-font-text type="body" v-else class="w-full lg:w-cols-5">{{
                event.text
              }}</ui-font-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.embla {
  max-width: 100%;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100vw;
  &__container {
    touch-action: pan-y pinch-zoom;
  }
  &__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
  }
}

.embla-thumbs {
  z-index: 3;
  &__slide {
    min-width: 0;
    z-index: 2;
  }

  &__slide--selected &__slide__number {
    color: var(--text-body);
  }
}

.embla__slide {
  transition: opacity 0.2s ease-in-out;
}
.embla__slide:not(.is-snapped) {
  opacity: 0.16;
}
.embla-thumbs__slide {
  transition: opacity 0.2s ease-in-out;
}
.embla-thumbs__slide:not(.embla-thumbs__slide--selected) {
  .year {
    opacity: 0.5;
  }
  .month {
    opacity: 0.15;
  }
  .ellipse {
    background-color: var(--text-theme);
    opacity: 0.75;
  }
}
.embla-thumbs__slide:not(.embla-thumbs__slide--selected) .ellipse {
  scale: 0.75;
}

.line {
  margin-top: -5px;
  width: 100vw;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    var(--text-theme) 10%,
    var(--text-theme) 50%,
    var(--text-theme) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  @media (min-width: 900px) {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      var(--text-theme) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  opacity: 0.5;
  z-index: 2;
}
</style>
