<template>
  <section class="link-cards h-full" ref="cardsContainer">
    <ui-bleed
      class="flex h-full flex-col gap-[var(--grid-bleed)] pb-spacing-06 pt-spacing-05 lg:flex-row lg:gap-[var(--grid-gap)] lg:pb-spacing-06-desktop lg:pt-spacing-04-desktop"
    >
      <component-element-link-card
        v-for="(card, index) in cards"
        :key="index"
        :card="card"
        :style="`--card-index:${index};--cards-length:${cards.length};--card-push:${index % 2 ? 'var(--push-3)' : ''};`"
        :class="{
          'aspect-portrait md:aspect-square xl:aspect-landscape': cards.length < 3,
          'aspect-small-card lg:aspect-portrait xl:aspect-small-card': cards.length > 2,
        }"
        class="card will-change-transform lg:ml-0 lg:mt-[calc(10rem_*_var(--card-index))] lg:w-[calc(var(--cols-12)/var(--cards-length))]"
      ></component-element-link-card>
    </ui-bleed>
  </section>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
  cards: Array,
})
const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})

const cardsContainer = ref(null)
let scrollTriggers = []

onMounted(() => {
  const cardsElements = cardsContainer.value.querySelectorAll('.card')
  if (isMobile.value) return
  cardsElements.forEach((card, index) => {
    const trigger = gsap.to(card, {
      yPercent: -10 * index,
      ease: 'power2.inOut',
      scrollTrigger: {
        trigger: cardsContainer.value,
        scrub: true,
        // markers: true,
      },
    }).scrollTrigger

    scrollTriggers.push(trigger)
  })
})

onUnmounted(() => {
  setTimeout(() => {
    scrollTriggers.forEach((trigger) => trigger.kill())
    scrollTriggers = []
  }, 1000)
})
</script>
