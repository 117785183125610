<template>
  <div
    class="header-menu fixed -top-[var(--header-top-mobile)] left-0 right-0 z-[calc(var(--z-index-header)+1)] flex h-[100dvh] flex-col justify-between border-stroke-light bg-nav-dark-green px-[var(--grid-bleed)] py-[var(--header-top-mobile)] lg:-top-[var(--header-top)] lg:py-[var(--header-top)]"
    :class="{
      parsedTheme,
    }"
    :style="parsedStyle"
  >
    <div class="flex w-full justify-between">
      <nuxt-link
        aria-label="Home"
        to="/"
        @click="router.currentRoute.value.fullPath === '/' ? lenisScrollTo(0) : null"
        class="logo lottie-logo lottie-logo-menu relative h-full w-[32px] transition-all lg:w-[40px]"
        data-cursor="empty"
      >
        <div class="absolute left-0 top-0 block w-full transition-all">
          <NuxtIcon name="menu-logo" class="icon" filled></NuxtIcon>
        </div>
      </nuxt-link>

      <div
        class="h-[40px] w-[40px] rounded-corner-02-desktop border border-stroke-light p-[10px] text-focus-light"
        @click="emit('isOpen')"
      >
        <NuxtIcon name="plus" class="icon block rotate-45" filled></NuxtIcon>
      </div>
    </div>
    <ul class="flex flex-col gap-spacing-04">
      <li v-for="(item, index) in menu" :key="item.label" :style="`--delay: 0.${index}s`">
        <ui-font-text
          type="labelMenu"
          class="text-focus-light"
          :class="{
            '!opacity-[.5]':
              router.currentRoute.value.path !== item.href &&
              router.currentRoute.value.path !== '/',
          }"
          ><nuxt-link :aria-label="item.title" :to="item.href" :target="item.target">{{
            item.title
          }}</nuxt-link></ui-font-text
        >
      </li>
    </ul>
    <button
    aria-label="Contact us"
      @click="openmodal"
      class="flex h-spacing-05-desktop w-full items-center rounded-corner-02 border-stroke-light bg-focus-light"
    >
      <ui-font-text
        type="label"
        class="mx-auto flex flex-row gap-2.5 text-[var(--text-theme)] text-focus-dark"
      >
        <div  class="flex flex-row gap-2.5">
          contact
          <NuxtIcon name="chevron-right" class="icon h-[10px] w-[10px] self-center"></NuxtIcon>
        </div>
      </ui-font-text>
    </button>
  </div>
</template>

<script setup>
const router = useRouter()
const props = defineProps({
  menu: Object,
  parsedTheme: String,
  parsedStyle: String,
  currentTheme: String,
})
const emit = defineEmits(['isOpen'])

const openmodal = () => {
  useEvent('modal:open', {
    data: null,
    type: 'contact',
  })
}
</script>

<style scoped>
.icon:deep(svg) {
  fill: white;
  width: 100%;
  height: 100%;
}
</style>
