export function getRandomIntBetween(min, max) {
    if (min > max) {
        [min, max] = [max, min];
    }

    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomFloatBetween(min, max, decimals) {
    if (min > max) {
        [min, max] = [max, min];
    }

    return (Math.random() * (max - min) + min).toFixed(decimals);
}