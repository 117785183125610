<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
const props = defineProps({
  steps: Array,
})

const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})
const spacing = computed(() => ({
  paddingBottom: isMobile.value ? 64 : 80,
  paddingTop: isMobile.value ? 144 : 160,
}))

const mediaStack = ref([])
const allSteps = ref([])
const { currentScroll } = useSmoothScroll()
const firstStepBottom = ref(0)
const stepContent = ref(null)
onMounted(() => {
  if (isMobile.value) return
  firstStepBottom.value = allSteps.value[1].$el.getBoundingClientRect().top
})

const updateScroll = () => {
  if (isMobile.value) return

  allSteps.value.forEach((step, index) => {
    const { top: stepTop, bottom: stepBottom } = step.$el.getBoundingClientRect()
    const { top: mediaTop, bottom: mediaBottom } = mediaStack.value[index].getBoundingClientRect()

    const progress = (mediaBottom - stepBottom) / (mediaBottom - mediaTop)
    const clampedProgress = Math.min(Math.max(progress, 0), 1)

    // Apply the clip-path to the current media
    const currentMedia = mediaStack.value[index]
    const nextMedia = mediaStack.value[index + 1]

    if (currentMedia) {
      // Reveal currentMedia from bottom to top
      currentMedia.style.clipPath = `inset(0 0 ${clampedProgress * 100}% 0)`
    }

    if (nextMedia) {
      // Reveal nextMedia from left to right
      nextMedia.style.clipPath = `inset(0 0 0 ${100 - clampedProgress * 100}%)`
    }

    // Debugging output
    // if (index === 0) console.log("Progress:", clampedProgress);
  })
}

watch(currentScroll, () => {
  if (!process.client) return
  updateScroll()
})

onMounted(() => {
  if (!process.client) return
  updateScroll()
})
</script>
<template>
  <section class="step-content" ref="stepContent">
    <ui-bleed
      class="flex flex-row gap-[calc(var(--push-1)+var(--grid-gap))] pb-spacing-06 pt-spacing-06 lg:pb-spacing-06-desktop lg:pt-spacing-06-desktop"
    >
      <div
        v-if="!isMobile"
        class="media-wrapper sticky top-spacing-08-desktop ml-[var(--push-1)] aspect-portrait h-full w-[var(--cols-4)] shrink-0 lg:mt-spacing-06-desktop ultra:top-spacing-09-desktop"
      >
        <div
          class="wrapper absolute left-0 top-0 aspect-portrait w-full overflow-hidden rounded-corner-02 lg:rounded-corner-02-desktop"
          v-for="(step, index) in steps"
          ref="mediaStack"
          :style="`z-index: ${steps.length - index}`"
        >
          <!-- <client-only>
            <component-behavior-parallax :speed="0.05"> -->
          <component-element-media
            class="media aspect-portrait origin-center"
            :media="step.media"
          ></component-element-media>
          <!-- </component-behavior-parallax>
          </client-only> -->
        </div>
      </div>
      <div
        class="steps flex flex-col gap-spacing-06 pt-spacing-06 lg:gap-spacing-06-desktop lg:pt-spacing-06-desktop"
      >
        <component-element-step-content
          :step="step"
          :index="index"
          :length="steps.length"
          v-for="(step, index) in steps"
          ref="allSteps"
        />
      </div>
    </ui-bleed>
  </section>
</template>
