<script setup>
import { gsap } from 'gsap'
import { useElementSize } from '@vueuse/core'

const props = defineProps({
  persons: Array,
  countLabel: String,
})
const peoplesLine = ref(null)
const currentPerson = ref(props.persons[0])
onMounted(() => {
  if (!process.client) return
  const items = peoplesLine.value.querySelectorAll('.nav-item')
  var strength = 50

  items.forEach((item) => {
    const magnet = item.querySelector('.magnetic')
    item.addEventListener('mousemove', function (event) {
      moveMagnet(event, magnet)
    })
    item.addEventListener('mouseout', function (event) {
      gsap.to(magnet, {
        x: 0,
        y: 0,
        ease: 'power4.easeOut',
        duration: 1,
      })
    })
  })

  function moveMagnet(event, magnet) {
    var magnetButton = magnet
    var bounding = magnetButton.getBoundingClientRect()
    gsap.to(magnetButton, {
      x: ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.5) * strength,
      y: ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.5) * strength,
      ease: 'power4.easeOut',
      duration: 1,
    })
  }

  // Select all .nav-item elements
  const navItems = document.querySelectorAll('.nav-item')

  // Variable to store the currently active item
  let activeItem = null

  // Helper function to add/remove a class to a sibling at a given offset
  const toggleSiblingClass = (items, index, offset, className, add) => {
    const sibling = items[index + offset]
    if (sibling) {
      sibling.classList.toggle(className, add)
    }
  }

  // Event listeners to toggle classes on click
  navItems.forEach((item, index) => {
    item.addEventListener('click', () => {
      currentPerson.value = props.persons[index]
      // If the same item is clicked, scale it down and fade in the other items
      if (activeItem === item) {
        item.classList.remove('hover')
        item.classList.remove('scale-up')
        item.classList.remove('fade-out')

        // Remove fade-out class from all items
        navItems.forEach((navItem) => {
          if (navItem !== item) {
            navItem.classList.remove('fade-out')
          }
        })
        // currentPerson.value = null
        activeItem = null // Clear active item
      } else {
        // If a new item is clicked, scale down the previous active item (if any)
        if (activeItem) {
          activeItem.classList.remove('hover')
          activeItem.classList.remove('scale-up')
          activeItem.classList.remove('fade-out')

          // Remove fade-out class from all items
          navItems.forEach((navItem) => {
            if (navItem !== item) {
              navItem.classList.remove('fade-out')
            }
          })
        }

        // Scale up the clicked item and apply fade effect to others
        item.classList.add('hover')
        item.classList.add('scale-up') // Assuming you have a CSS class for scale-up effect
        item.classList.remove('fade-out') // Ensure the active item remains visible

        // Fade out the other items
        navItems.forEach((navItem) => {
          if (navItem !== item) {
            navItem.classList.add('fade-out')
          }
        })

        // Update active item to the clicked item
        activeItem = item
      }
    })
  })
  /**
   * Make the first item active by default
   */
  if (navItems.length > 0) {
    const firstItem = navItems[0]
    firstItem.classList.add('hover', 'scale-up')
    // Fade out the other items
    navItems.forEach((item, idx) => {
      if (idx !== 0) {
        item.classList.add('fade-out')
      }
    })
    // Set activeItem
    activeItem = firstItem
    currentPerson.value = props.persons[0]
  }
})
const personsPlaceholder = ref(null)
const infosHeight = ref(0)
onMounted(() => {
  infosHeight.value = personsPlaceholder.value.getBoundingClientRect().height
})
</script>

<template>
  <div class="peoples-line relative grid h-full w-full gap-24" ref="peoplesLine">
    <div class="nav-wrap">
      <nav class="nav-bar">
        <ul class="nav-list">
          <li class="nav-item" v-for="(person, index) in persons" :key="person.index">
            <div
              class="nav-item__link relative aspect-[1/1] h-[110px] w-auto cursor-pointer ultra:h-[170px]"
              :key="index"
              data-curor="empty"
            >
              <component-element-media
                class="magnetic pointer-events-none aspect-[1/1] h-full w-auto overflow-hidden rounded-full object-cover"
                v-if="person.media"
                :media="person.media"
              ></component-element-media>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div
      class="people-informations relative ml-push-6 w-cols-6 pl-bleed transition-all duration-1000 ease-in-out"
      :style="{ height: infosHeight + 'px' }"
    >
      <transition name="fade" mode="out-in">
        <div
          :key="currentPerson"
          class="infos lg:w-[var(--cols-4)] 2xl:w-[var(--cols-3)]"
          v-if="currentPerson"
        >
          <ui-font-text type="h2" class="pb-spacing-02-desktop" v-if="currentPerson.title">{{
            currentPerson.title
          }}</ui-font-text>
          <ui-font-text type="label" class="pb-spacing-04-desktop" v-if="currentPerson.position">{{
            currentPerson.position
          }}</ui-font-text>
          <ui-font-text type="smallBody" class="pb-spacing-04-desktop" v-if="currentPerson.text">{{
            currentPerson.text
          }}</ui-font-text>
          <nuxt-link
            data-cursor="empty"
            v-if="currentPerson.linkedinUrl"
            class="block w-[24px]"
            :to="currentPerson.linkedinUrl"
            target="_blank"
            :aria-label="`Linkedin ${currentPerson.title}`"
          >
            <NuxtIcon class="icon download-icon" name="linkedin" filled
          /></nuxt-link>
        </div>
      </transition>
      <div
        class="persons-height-placeholder pointer-events-none absolute left-0 top-0 flex h-auto w-full opacity-0"
        ref="personsPlaceholder"
      >
        <div
          v-for="(person, index) in persons"
          :key="index"
          class="infos h-full shrink-0 lg:w-[var(--cols-4)] 2xl:w-[var(--cols-3)]"
        >
          <ui-font-text type="h2" class="pb-spacing-02-desktop" v-if="person.title">{{
            person.title
          }}</ui-font-text>
          <ui-font-text type="label" class="pb-spacing-04-desktop" v-if="person.position">{{
            person.position
          }}</ui-font-text>
          <ui-font-text type="smallBody" class="pb-spacing-04-desktop" v-if="person.text">{{
            person.text
          }}</ui-font-text>
          <nuxt-link
            data-cursor="empty"
            v-if="person.linkedinUrl"
            class="block w-[24px]"
            :to="person.linkedinUrl"
            :aria-label="`Linkedin ${person.title}`"
          >
            <NuxtIcon class="icon download-icon" name="linkedin" filled
          /></nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.peoples-line {
  .nav-wrap {
    justify-content: center;
    align-items: flex-end;
    display: flex;
  }

  .nav-list {
    flex-flow: row;
    justify-content: center;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    font-size: 1.4vw;
    height: 200px;
    gap: var(--grid-gap);
    @media (min-width: 2000px) {
      height: 250px;
      gap: calc(var(--grid-gap) * 2);
      max-width: calc(100vw - var(--grid-gap) * 2);
    }
  }

  .nav-item {
    justify-content: center;
    align-items: center;
    width: 5em;
    transition:
      width 0.5s cubic-bezier(0.16, 1, 0.3, 1),
      opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1),
      scale 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    display: flex;
    position: relative;
    user-select: none;
  }

  .nav-item.hover {
    width: 8em;
  }

  .nav-item.sibling-close {
    width: 7em;
  }

  .nav-item.sibling-far {
    width: 6em;
  }

  .nav-item__link {
    z-index: 1;
    pointer-events: auto;
  }

  .nav-item:nth-child(odd) {
    align-self: start;
  }

  .nav-item:nth-child(even) {
    align-self: end;
  }
  .nav-item {
    opacity: 1;
  }

  .nav-item.fade-out {
    opacity: 0.3;
  }

  .nav-item.hover {
    opacity: 1 !important;
    scale: 1.2;
  }
}
</style>
