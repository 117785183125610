<script setup>
const original = ref(null);
const copy = ref(null);

onMounted(() => {
  if (original.value && copy.value) {
    const clone = original.value.children[0].cloneNode(true);
    copy.value.appendChild(clone);
  }
});
</script>

<template>
  <div class="sliding-item relative h-fit">
    <div ref="copy" class="copy absolute left-0 top-0 opacity-[.5]"></div>
    <div ref="original" class="original h-fit"><slot /></div>
  </div>
</template>

<style>
.copy {
  transform: translateY(-200%);
  transition-delay: 0.5s;
  opacity: 0;
}
.original,
.copy {
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
</style>
