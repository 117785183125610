<script setup>
const props = defineProps({
  card: Object,
})

const { isMobileOrTablet } = useDevice()
const { width: innerWidth } = useWindowSize()

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet
  return innerWidth.value < 900
})
</script>
<template>
  <div
    class="link-card relative flex h-full !max-h-[85vh] flex-col justify-between overflow-hidden rounded-corner-02 border border-stroke-dark bg-transparent-background-dark p-[20px] lg:rounded-corner-02-desktop lg:p-[40px]"
  >
    <div class="flex flex-row justify-between">
      <ui-font-text type="label" class="text-focus-light">{{ card.trumpet }}</ui-font-text>
      <NuxtIcon
        v-if="card.cta?.href"
        name="arrow-down"
        class="icon h-[10px] lg:h-[16px]"
      ></NuxtIcon>
    </div>
    <div class="flex flex-col" :class="{ 'gap-spacing-04 lg:gap-spacing-04-desktop': card.body }">
      <ui-font-text type="h3" class="text-focus-light">{{ card.headline }}</ui-font-text>

      <ui-font-text type="body" class="text-focus-light">{{ card.body }}</ui-font-text>
    </div>
    <ui-call-to-action
      :is-card="true"
      data-cursor="empty"
      class="absolute right-0 top-0 z-[var(--z-index-content-over-drop)] h-full w-full"
      :cta="card.cta"
    ></ui-call-to-action>
  </div>
</template>
