<script setup>
const props = defineProps({
  sections: Array,
});
const sectionsRef = ref(null);
function camelCaseToWords(s) {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const debug = computed(() => useRoute().query.sections !== undefined);

function wrapSectionsWithSameTheme(sections) {
  const wrappedSections = [];
  let currentThemeSlug = null;
  let currentThemeSections = [];

  sections.forEach((section) => {
    const themeSlug = section.data.theme.slug;

    if (themeSlug === currentThemeSlug) {
      currentThemeSections.push(section);
    } else {
      if (currentThemeSections.length > 0) {
        wrappedSections.push({
          themeSlug: currentThemeSlug,
          sections: currentThemeSections,
        });
      }
      currentThemeSections = [section];
      currentThemeSlug = themeSlug;
    }
  });

  if (currentThemeSections.length > 0) {
    wrappedSections.push({
      themeSlug: currentThemeSlug,
      sections: currentThemeSections,
    });
  }

  return wrappedSections;
}
const route = useRoute();
const isHomepage = computed(() => route.path === "/");
</script>

<template>
  <div class="sections" ref="sectionsRef">
    <template
      v-for="(group, groupIdx) in wrapSectionsWithSameTheme(sections)"
      :key="groupIdx"
    >
      <div class="section-group overflow-clip" :data-theme="group.themeSlug">
        <nuxt-img
          class="grainy-drop"
          src="/drop-dark.png"
          alt="water-drop"
          sizes="100vw sm:50vw md:400px"
          v-if="
            isHomepage &&
            (group.themeSlug === 'black' || group.themeSlug === 'dark-green')
          "
        />
        <nuxt-img
          class="grainy-drop"
          src="/drop-clear.png"
          alt="water-drop"
          sizes="100vw sm:50vw md:400px"
          v-else-if="isHomepage && group.themeSlug === 'green'"
        />
        <nuxt-img
          class="grainy-drop"
          src="/drop-darker.png"
          alt="water-drop"
          sizes="100vw sm:50vw md:400px"
          v-else-if="isHomepage && group.themeSlug === 'ivory'"
        />
        <component-section
          v-for="({ id, type, data }, idx) in group.sections"
          :data-module-name="debug ? camelCaseToWords(type) : null"
          :class="{
            'debug-module': debug,
            'z-[var(--z-index-content-over-drop)]': group.themeSlug === 'white',
            relative:
              (type === 'contentSection' && group.themeSlug === 'white') ||
              type === 'callout',
          }"
          :type="type"
          :data="data"
          :idx="idx"
          :id="id"
          :key="id"
          :current-theme="group.themeSlug"
        />
      </div>
    </template>
  </div>
</template>

<style lang="postcss">
.sections {
  /* shows the module's name and adds a border to it */
  .debug-module {
    position: relative;

    &:before {
      box-shadow: inset 0px 0px 0px 2px dodgerblue;
      content: attr(data-module-name);
      position: absolute;
      text-transform: uppercase;
      font-family: monospace;
      font-size: 10px;
      padding: 1px 10px;
      font-weight: bold;
      color: dodgerblue;
      z-index: 1000;
      inset: 0;
      pointer-events: none;

      @media (--md) {
        font-size: 14px;
        padding: 14px;
      }
    }
  }
}

.section-group {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
</style>
